<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6"
        label="Nome"
        v-model="internalUser.firstName"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
        :class="edit ? 'col-lg-4' : 'col-lg-3'"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Cognome"
        v-model="internalUser.lastName"
        :errorMsg="errorHash.surname"
        @input="removeFieldError('surname')"
        :class="edit ? 'col-lg-4' : 'col-lg-3'"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Email"
        v-model="internalUser.email"
        type="email"
        :disabled="edit"
        :errorMsg="errorHash.email"
        @input="removeFieldError('email')"
        :class="edit ? 'col-lg-4' : 'col-lg-3'"
      />

      <FormInputTextWidget
        v-if="!edit"
        class="col-md-6 col-lg-3"
        label="Password"
        v-model="internalUser.password"
        type="password"
        :errorMsg="errorHash.password"
        @input="removeFieldError('password')"
      />

      <div class="col-12 mt-4 text-right">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import { emailIsValid } from '@/libs/Form/helpers/validation.js';

import validateMixin from '@/libs/Form/mixins/validate.js';
import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import rolesMixin from '@/mixins/roles.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin, modalsManagerMixin, rolesMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FeedbackAlert,
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    internalUser: {
      get () {
        return this.user;
      },
      set (user) {
        this.$emit('update:user', user);
      },
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.internalUser.firstName) {
        this.addError('Inserisci il nome', 'name');
      }
      if (!this.internalUser.lastName) {
        this.addError('Inserisci il cognome', 'surname');
      }

      if (!this.edit) {
        if (!this.internalUser.email) {
          this.addError('Inserire l\'email.', 'email');
        } else if (!emailIsValid(this.internalUser.email)) {
          this.addError('Email non valida.', 'email');
        }
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const userToReturn = { ...this.user };

      this.$emit('submitForm', userToReturn);
    },
  },
};

</script>
